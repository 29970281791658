@import "../../styles/colors/colors.css";

.mapControl {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 1;
}

/* spacecraft or globe icon container */
.mapStyleButton {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 150px;
  right: 30px;
  background: radial-gradient(#fff, #fff, #fff, #000);
  color: var(--secondaryTextOnLightBackgrounds);
  border-radius: 10px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1;
}

/* spacecraft or globe icon */
.mapButtonIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}

.mapTooltipContainer {
  z-index: 1;
  position: absolute;
  color: #a0a7b4;
}

.mapTooltip {
  background-color: var(--darkBackground);
  padding: 15px;
}

.mapTooltipTriangle {
  width: 0;
  height: 0;
  /* the triangle half width is 20px, consistent with the map.tooltip.tsx */
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 40px solid var(--darkBackground);
}

.mapCloseTooltip {
  cursor: pointer;
  float: right;
  display: inline-block;
  padding: 2px 2px;
}

.mapCloseTooltip:hover {
  cursor: pointer;
  float: right;
  display: inline-block;
  padding: 2px 2px;
  color: #fff;
}

.mapTooltipButton {
  background-color: var(--primaryLightBrandBackgroundColor);
  color: var(--textWhite);
  margin-right: 10px;
  
}

.enlargeCompareImage,
.minmizeComapreImage {
  background-color: var(--primaryLightBrandBackgroundColor);
  color: var(--textWhite);
  margin-top: 10px;
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
}

.minmizeComapreImage{
  margin-left: 20px;
}

.mapTooltipButton:disabled {
  background-color: var(--disabledSecondaryTextOnDarkBackgrounds);
  color: var(--secondaryTextOnDarkBackgrounds);
  pointer-events: none;
}

.compareButtonContainer {
  text-align: center;
}

.topRightMainButton,
.topRightCancelButton,
.topRightLocationIDSwitch {
  position: absolute;
  right: 5pt;
  cursor: pointer;
  padding: 2pt 5pt;
  border-radius: 5pt;
}

.topRightMainButton {
  top: 5pt;
  background-color: var(--primaryLightBrandBackgroundColor);
  color: var(--textWhite);
}

.topRightCancelButton {
  top: 31pt;
  background-color: var(--disabledSecondaryTextOnDarkBackgrounds);
  color: var(--secondaryTextOnDarkBackgrounds);
}

.topRightLocationIDSwitch {
  top: 57pt;
  color: var(--textWhite);
}

.bottomBannerContainer {
  z-index: 1;
  position: absolute;
  bottom: 10pt;
  left: 10pt;
  right: 10pt;
  text-align: center;
}

.bottomBanner {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.5);
  padding-left: 4px;
  padding-right: 4px;
}

.ant-modal-title {
  color: white !important;
}