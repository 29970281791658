.formHeading {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: normal;
    color: #484848;
    margin: 0px;
    padding: 0px;
}

.formActionButton {
    line-height: 5px;
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: normal;
    font-weight: 800;
    border-width: 2px;
    padding: 22px 22px;
    box-shadow: none;
    background-color: var(--primaryLightBrandBackgroundColor);
    border-color: transparent;
    color: #ffffff;
}

.errorContainer {
    min-height: 60px;
}

.inputAndLabelPlaceholder {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 1em;
}

/**
* Add a transition to the label and input.
* I'm not even sure that touch-action: manipulation works on
* inputs, but hey, it's new and cool and could remove the
* pesky delay.
*/
label, input {
    transition: all 0.2s;
    touch-action: manipulation;
}

input {
    background-color: whitesmoke;
    font-size: 1.5em;
    border: 0;
    border-bottom: 1px solid darkgrey;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    cursor: text;
}

input:focus {
    outline: 0;
}

label {
    text-transform: capitalize;
    letter-spacing: 0.05em;
}
/**
* Translate down and scale the label up to cover the placeholder,
* when following an input (with placeholder-shown support).
* Also make sure the label is only on one row, at max 2/3rds of the
* field—to make sure it scales properly and doesn't wrap.
*/
input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
}
/**
* By default, the placeholder should be transparent. Also, it should
* inherit the transition.
*/
::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
}
::-moz-placeholder {
    opacity: 0;
    transition: inherit;
}
::-ms-input-placeholder {
    opacity: 0;
    transition: inherit;
}
/**
* Show the placeholder when the input is focused.
*/
input:focus::-webkit-input-placeholder {
    opacity: 1;
}
input:focus::-moz-placeholder {
    opacity: 1;
}
input:focus::-ms-input-placeholder {
    opacity: 1;
}

/**
* When the element is focused, remove the label transform.
* Also, do this when the placeholder is _not_ shown, i.e. when
* there's something in the input at all.
*/
input:not(:placeholder-shown) + label,
input:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
}
