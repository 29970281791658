:root {
  --primaryLightBrandBackgroundColor: #3e7bc7;
  --primaryDarkBrandBackgroundColor: #1e2e61;
  --primaryAccentBackgroundColor: #de5d55;
  --secondaryAccentColdBackgroundColor: #266d7a;
  --textOnLightBackgrounds: #192133;
  --secondaryTextOnLightBackgrounds: #3e4757;
  --disabledSecondaryTextOnLightBackgrounds: #848d9c;
  --textOnDarkBackgrounds: #c1c8d4;
  --secondaryTextOnDarkBackgrounds: #c1c8d4;
  --disabledSecondaryTextOnDarkBackgrounds: #000000;
  --darkBackground: #29323c;
  --textWhite: #ffffff;
  --hoveredTableRowBackgroundColor: #ffffff;
}
