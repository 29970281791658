.publicPageImage {
    background-image: url('../../assets/landing_page.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: overlay;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
    z-index: 10;
}

.logoPublicPage {
    margin-left: 20px;
}

.formContainer {
    position: absolute;
    left: 5%;
    top: 10%;
    background-color: whitesmoke;
    border-radius: 4px;
    padding: 32px 32px 24px;
    width: 441px;
    box-shadow: 0 16px 40px rgba(0,0,0,0.12);
}

@media screen and (max-width: 744px) {
    .formContainer {
        width: 80vw;
        left: 10%;
    }
}
