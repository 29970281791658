.viewportHeight {
  height: 100vh;
}

.layout {
  composes: viewportHeight;
  position: relative;
  overflow: hidden;
}

.content {
  height: 100%;
  overflow-y: scroll;
}

.footer {
  text-align: center;
  padding: 0 0;
  height: 5%;
}

.sider {
  height: 100%;
  width: 50px;
}
