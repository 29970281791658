.formContainer {
  background-color: whitesmoke;
  padding: 30px 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.subcontainer {
  width: clamp(440px,100%,400px);
}

.page {
  background-color: var(--darkBackground);
  height: 100%;
  width: 100%;
}

.sendFeedbackButton {
  background-color: var(--primaryLightBrandBackgroundColor);
  color: var(--textWhite);
}

.sendFeedbackButton:disabled {
  background-color: var(--disabledSecondaryTextOnDarkBackgrounds);
  color: var(--secondaryTextOnDarkBackgrounds);
  pointer-events: none;
}

.fullWidthTable {
  width: 100%;
}

.fullWidthTable tr:hover {
  background: var(--hoveredTableRowBackgroundColor);
}
