body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Height for image compare skeleton */
div.ant-modal-body
  > div[style*='height: 0px;']:first-of-type:not([data-testid*='container']) {
  height: 100% !important;
}

.ant-modal-body,
.ant-modal-header,
.ant-modal-footer {
  background-color: var(--darkBackground) !important;
}

.drawer.drawer-open > .drawer-content-wrapper {
  box-shadow: 5px 4px 8px 0 rgba(0, 0, 0, 0.4),
    5px 6px 20px 0 rgba(0, 0, 0, 0.39) !important;
}

.ant-modal-close-x {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
}

/* .ant-modal-close:hover {
  color: rgba(255, 255, 255, 0.75);
  font-size: 20px;
} */
aside.ant-layout-sider-collapsed div.ant-layout-sider-children div {
  content: url('../src/assets/logo-icon.png');
}

.ant-modal-title {
  color: white;
}

.ant-btn .ant-btn-primary .ant-btn-round .ant-btn-sm {
  background-color: var(--primaryLightBrandBackgroundColor);
}

.events-page-comment .ant-modal-body,
.events-page-comment .ant-modal-header,
.events-page-comment .ant-modal-footer {
  background-color: var(--lightBackground) !important;
}

.events-page-comment .ant-modal-title,
.events-page-comment .ant-modal-close-icon {
  color: rgba(0, 0, 0, 0.85);
}

div.MapDeckComment
  > div.ant-modal-content
  > div.ant-modal-body
  > form
  > div:nth-child(n)
  > div.ant-col.ant-form-item-label
  > label {
  color: white !important;
}

.ant-modal-content > div > div > div:nth-child(4) > div {
  transform: translate(30%, 400%) !important;
}

.ant-modal-content > div > div > div:nth-child(5) > div {
  transform: translate(-30%, 400%) !important;
}
body
  > div:nth-child(9)
  > div
  > div.ant-modal-wrap.ant-modal-centered
  > div
  > div.ant-modal-content
  > div.ant-modal-body
  > form
  > span
  > div:nth-child(2)
  > div.ant-col.ant-form-item-label
  > label,
body
  > div:nth-child(9)
  > div
  > div.ant-modal-wrap.ant-modal-centered
  > div
  > div.ant-modal-content
  > div.ant-modal-body
  > form
  > span
  > div:nth-child(1)
  > div.ant-col.ant-form-item-label
  > label,
body
  > div:nth-child(9)
  > div
  > div.ant-modal-wrap.ant-modal-centered
  > div
  > div.ant-modal-content
  > div.ant-modal-header
  > div {
  color: white !important;
}

.video-modal .ant-modal-content {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.video-modal .ant-modal-body {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.history-modal .ant-modal-body,
.history-modal .ant-modal-header {
  background-color: white !important;
}
.history-modal .ant-modal {
  background: transparent !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
